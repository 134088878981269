// @flow

import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useIntl } from 'gatsby-plugin-intl'
import messages from './FreshMSPage.lang'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
// components
import ProductTeaser2 from './../ProductTeaser2'
import ValueBox from './../ValueBox'
import Products from '../../../components/Products'
import FreshmsInfographic from './../InfographicTeasers/FreshmsInfographic'
import DescriptionTeaser from './../DescriptionTeaser'
import VideoTeaser from './../VideoTeaser'
import ValueTeaser from './../ValueTeaser'
import logoTsbo from '../../../images/logo/travelsandbox.svg'
import logoFreshms from '../../../images/logo/freshms.svg'

// styles
import baseStyles from './FreshMSPage.scss'

/* eslint-disable max-len */
const videoDesktop = (
  <StaticImage
    src="../../../images/products/freshms/freshms-videoteaser-desktop.png"
    alt=""
    objectFit="fill"
    className="imageCover"
  />
)
const descriptionDesktop = (
  <StaticImage
    src="../../../images/products/freshms/freshms-teaser4-desktop.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const valueDesktop = (
  <StaticImage
    src="../../../images/products/freshms/freshms-teaser3-desktop.jpg"
    alt=""
    objectFit="cover"
    className="imageCover"
  />
)
const bgDesktop = (
  <StaticImage
    src="../../../images/products/freshms/freshms-bg-desktop.jpg"
    alt=""
    objectFit="cover"
    className="imageCover"
  />
)
const teaser1Desktop = (
  <StaticImage
    src="../../../images/products/freshms/freshms-teaser1-desktop.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
/* eslint-enable max-len */

type Props = {
  ...StyleProps
}

const productValues1 = ['freshmsValue1', 'freshmsValue2', 'freshmsValue3', 'freshmsValue4', 'freshmsValue5']

const productValues2 = ['freshmsValue1a', 'freshmsValue2a', 'freshmsValue3a', 'freshmsValue4a', 'freshmsValue5a']

const productValues3 = ['freshmsValue1b', 'freshmsValue2b', 'freshmsValue3b', 'freshmsValue4b', 'freshmsValue5b']

const descriptionValues = [
  'freshmsDescription1',
  'freshmsDescription2',
  'freshmsDescription3',
  'freshmsDescription4',
  'freshmsDescription5'
]

const FreshmsPage = ({ styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <ProductTeaser2
        className={styles.freshmsTeaser}
        logo={logoFreshms}
        backBtn={formatMessage(messages.backBtn)}
        teaserText={formatMessage(messages.teaserText)}
        btnText={formatMessage(messages.btnText)}
        imageDesktop={teaser1Desktop}
        bgImageDesktop={bgDesktop}
        hiddenPageTitle="freshMS"
      />
      <ValueBox
        productValues1={productValues1}
        productValues2={productValues2}
        productValues3={productValues3}
        icon1="icon-consulting"
        icon2="icon-compass"
        icon3="icon-strategy"
        title1={formatMessage(messages.valueBoxTitle1)}
        title2={formatMessage(messages.valueBoxTitle2)}
        title3={formatMessage(messages.valueBoxTitle3)}
      />
      <VideoTeaser
        title={formatMessage(messages.videoTeaserTitle)}
        videoUrl="https://www.youtube.com/embed/fSpR6ZAc1OA"
        imageDesktop={videoDesktop}
        type="freshms"
      />
      <FreshmsInfographic />
      <ValueTeaser
        title={formatMessage(messages.valueTeaserTitle)}
        contentText={formatMessage(messages.valueTeaserContent)}
        btnText={formatMessage(messages.valueTeaserBtnText)}
        btnLink="/products/travelsandbox/"
        mobileLogo={logoTsbo}
        desktopLogo={logoTsbo}
        imageDesktop={valueDesktop}
      />
      <DescriptionTeaser
        className={styles.descriptionTeaser}
        title={formatMessage(messages.descriptionTeaserTitle)}
        contentText={formatMessage(messages.descriptionTeaserContent)}
        values={descriptionValues}
        btnText={formatMessage(messages.descriptionTeaserBtn)}
        btnLink="/contact/"
        imageDesktop={descriptionDesktop}
      />
      <Products type="freshms" />
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(FreshmsPage)
