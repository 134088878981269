// @flow

import React from 'react'
import cn from 'classnames'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import baseStyles from './IconItem.scss'

type Props = {
  ...StyleProps,
  icon: string,
  label: string,
  color: string
}

const IconItem = ({ styles, icon, label, color }: Props) => {
  return (
    <div className={styles.root}>
      <span
        className={cn(styles.iconWrapper, styles['is-' + color])}
      >
        <span
          className={cn(styles.icon, icon)}
        />
      </span>
      <span className={styles.label}>
        {label}
      </span>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(IconItem)
