/* eslint-disable max-len */

import { defineMessages } from 'react-intl'

export default defineMessages({
  backBtn: 'Back to overview',
  teaserText: `freshMS allows you to adapt your site dynamically in just a few steps.
  The power of freshMS is its intuitive user interface with realtime API connectivity.`,
  btnText: 'Check it out',
  valueTeaserTitle: 'Profit from a solid, flexible & affordable platform foundation',
  valueTeaserContent: `The TravelSandbox® is designed to be your quickstart foundation to guarantee a short time to market process.
  If you want to, it’s of course possible to extend the base with custom features and general modifications to get the perfect solution for your business. And to be sustainable and flexible it doesn’t matter on which product or content sources you rely.`,
  videoTeaserTitle: 'Our freshMS at a glance',
  valueTeaserBtnText: 'Learn more about TravelSandbox®',
  valueBoxTitle1: 'Make your life easier',
  valueBoxTitle2: 'Real data in real time',
  valueBoxTitle3: 'The flexible approach',
  descriptionTeaserTitle: 'Manage your on page marketing in a whole new way',
  descriptionTeaserContent: `freshMS allows you to create and edit your website content
  in an intuitive way with real time product data like content and prices.
  Direct inline editing in the real view of your website makes it very simple to work intuitively.
  This has led to an easy-to-understand feature-rich content management system for e-commerce.
  It removes the need for working in complex backend systems and simplifies your daily work.`,
  descriptionTeaserBtn: 'Get in touch now',
  visualEditing: 'Visual and intuitive content editing '
})


