// @flow

import React from 'react'
// import { ArcherContainer, ArcherElement } from 'react-archer'
import cn from 'classnames'
import { useIntl } from 'gatsby-plugin-intl'
import messages from './FreshmsInfographic.lang'
import ScrollAnimation from 'react-animate-on-scroll'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { Container } from '../../../../components/basic/Grid'
import baseStyles from './FreshmsInfographic.scss'
import IconItem from './../../IconItem'
import productDesktop from '../../../../images/products/freshms/freshms-teaser2-desktop.png'
import productTablet from '../../../../images/products/freshms/freshms-teaser2-tablet.png'
import productTablet2x from '../../../../images/products/freshms/freshms-teaser2-tabletx2.png'
import productMobile from '../../../../images/products/freshms/freshms-teaser2-mobile.png'
import productMobile2x from '../../../../images/products/freshms/freshms-teaser2-mobilex2.png'
import arrow from '../../../../images/products/freshms/infographic-freshms-arrow.svg'
import arrows from '../../../../images/products/freshms/infographic-freshms-arrows.svg'

type Props = {
  ...StyleProps
}

const FreshmsInfographic = ({ styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <Container className={styles.container} fluid>
        <div className={styles.products}>
          <span className={styles.title}>
            {formatMessage(messages.title)}
            <b className={styles.lastLetter}>.</b>
          </span>
          <span className={styles.contentText}>{formatMessage(messages.contentText)}</span>
          <ScrollAnimation className={styles.topAnimation} animateIn="fadeInUp" animateOnce delay={100}>
            <div className={styles.contentList}>
              <span className={styles.subtitle}>
                {formatMessage(messages.subtitle1)}
                <b className={styles.lastLetter}>.</b>
              </span>
              <div className={styles.contentIcons}>
                <div className={styles.contentIcon}>
                  <span className={cn(styles.iconWrapper, styles['is-black'])}>
                    <span className={cn(styles.icon, 'icon-checklist')} />
                  </span>
                  <span className={styles.label}>{formatMessage(messages.contentLabel1)}</span>
                  <img
                    className={cn(styles.contentIconArrow, styles.contentIconArrowFirst)}
                    src={arrow}
                    alt="content arrows"
                  />
                </div>
                <div className={styles.contentIcon}>
                  <span className={cn(styles.iconWrapper, styles['is-black'])}>
                    <span className={cn(styles.icon, 'icon-system-integration')} />
                  </span>
                  <span className={styles.label}>{formatMessage(messages.contentLabel2)}</span>
                  <img
                    className={cn(styles.contentIconArrow, styles.contentIconArrowSecond)}
                    src={arrow}
                    alt="content arrows"
                  />
                </div>
                <div className={styles.contentIcon}>
                  <span className={cn(styles.iconWrapper, styles['is-black'])}>
                    <span className={cn(styles.icon, 'icon-clock')} />
                  </span>
                  <span className={styles.label}>{formatMessage(messages.contentLabel3)}</span>
                  <img
                    className={cn(styles.contentIconArrow, styles.contentIconArrowThird)}
                    src={arrow}
                    alt="content arrows"
                  />
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
        <div className={styles.infographic}>
          <div className={styles.colLeft}>
            <ScrollAnimation className={styles.leftAnimationContainer} animateIn="fadeIn" animateOnce delay={200}>
              <picture className={styles.productImg}>
                <source media={'(min-width: 1440px)'} srcSet={`${productDesktop} 2x, ${productDesktop} 3x`} />
                <source media={'(min-width: 768px)'} srcSet={`${productTablet} 1x, ${productTablet2x} 2x`} />
                <source media={'(max-width: 767px)'} srcSet={`${productMobile} 1x, ${productMobile2x} 2x`} />
                <img className={styles.productImg} src={productDesktop} alt="product image" />
              </picture>
            </ScrollAnimation>
          </div>
          <div className={styles.colRight}>
            <ScrollAnimation className={styles.possibilitiesContainer} animateIn="fadeIn" animateOnce delay={300}>
              <span className={styles.subtitle}>
                {formatMessage(messages.subtitle2)}
                <b className={styles.lastLetter}>.</b>
              </span>
              <IconItem
                className={styles.iconPossibility}
                label={formatMessage(messages.iconLabel1)}
                color="primary"
                icon="icon-pen-and-ruler"
              />
              <IconItem
                className={styles.iconPossibility}
                label={formatMessage(messages.iconLabel2)}
                color="primary"
                icon="icon-calendar-heart"
              />
              <IconItem
                className={styles.iconPossibility}
                label={formatMessage(messages.iconLabel3)}
                color="primary"
                icon="icon-upload"
              />
              <IconItem
                className={styles.iconPossibility}
                label={formatMessage(messages.iconLabel4)}
                color="primary"
                icon="icon-design"
              />
              <IconItem
                className={styles.iconPossibility}
                label={formatMessage(messages.iconLabel5)}
                color="primary"
                icon="icon-complex"
              />
              <img className={styles.possibilitesArrows} src={arrows} alt="infographic arrows" />
              <img className={styles.possibilitesArrowMobile} src={arrow} alt="infographic arrows" />
            </ScrollAnimation>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(FreshmsInfographic)
