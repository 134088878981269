/* eslint-disable max-len */

import { defineMessages } from 'react-intl'

export default defineMessages({
  title: 'Manage your application easier than ever',
  contentText: 'With the native support of our freshMS you are able to manage your on page marketing activities in a convinient way without the need of confusing CMS backends. Benefit from live prices and availabilities for your offers and schedule your promotions as you like.',
  subtitle1: 'Your content & product sources',
  subtitle2: 'The possibilities',
  contentLabel1: 'Non bookable content',
  contentLabel2: 'Product details',
  contentLabel3: 'Live prices',
  iconLabel1: 'Intuitive content editing',
  iconLabel2: 'Schedule marketing campaigns',
  iconLabel3: 'Build up pages in no time',
  iconLabel4: 'Display dynamic content',
  iconLabel5: 'Maintain multiple sources'
})


